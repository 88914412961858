const BendService = {
    configure: configure,
    postUser: postUser,
    getActiveUser: getActiveUser,
    updateUser: updateUser,
    exchange: exchange,
    $post: $post,
    $put: $put,
    $get: $get,
    $delete: $delete,
    getAppKey: getAppKey,
    getUserTokenAuth: getUserTokenAuth,
    getAppAuth: getAppAuth,
    handleError: handleError,
    handleSuccess: handleSuccess,
    getUser
};

let appKey;
let appSecret;
let encodedAppCredentials;

const BASE_URL = 'https://api.bend.io';

function $get(url, fetchOptions = {}) {
    return exchange(url, Object.assign(fetchOptions, {method: 'get'}))
}

function $put(url, body, fetchOptions) {
    return exchange(url, Object.assign(fetchOptions, {method: 'put', body: JSON.stringify(body || {})}))
}

function $post(url, body, fetchOptions) {
    return exchange(url, Object.assign(fetchOptions, {method: 'post', body: JSON.stringify(body || {})}))
}

function $delete(url, fetchOptions) {
    return exchange(url, Object.assign(fetchOptions, {method: 'delete'}))
}

function getAppKey() {
    return appKey;
}

function getUserTokenAuth() {
    return {'Authorization': `Bend ${window.localStorage.getItem('authtoken')}`};
}

// TODO: getUserBasicAuth?

function getAppAuth() {
    return {'Authorization': `Basic ${encodedAppCredentials}`}
}

function configure(key, secret) {
    appKey = key;
    appSecret = secret;
    encodedAppCredentials = btoa(`${appKey}:${appSecret}`)
}

function isConfigured() {
    if (!appKey || !appSecret || !encodedAppCredentials) {
        console.error("Before using BendService you must call BendService.config with appKey and appSecret!")
        return false;
    } else {
        return true;
    }
}

function postUser(endpoint, body) {
    return exchange(`/user/${appKey}/${endpoint}`, {
        method: 'post',
        body: JSON.stringify(body || {}),
        headers: {'Authorization': `Basic ${encodedAppCredentials}`}
    })
}

//todo(mikol): move these to the user service once we decouple response handers / auth header creation.

function getActiveUser() {
    return exchange(`/user/${appKey}/_me`, {
        method: 'get',
        headers: {'Authorization': `Bend ${window.localStorage.getItem('authtoken')}`}
    })
}

function getUser(id) {
    return exchange(`/user/${appKey}/${id}`, {
        method: 'get',
        headers: {'Authorization': `Bend ${window.localStorage.getItem('authtoken')}`}
    })
}

function updateUser(user) {
    return exchange(`/user/${appKey}/${user._id}`, {
        method: 'put',
        body: JSON.stringify(user || {}),
        headers: {'Authorization': `Bend ${window.localStorage.getItem('authtoken')}`}
    })
}

//todo ^^^

function exchange(url, fetchOptions) {
    if (!isConfigured()) return;
    return fetch(BASE_URL + url, fetchOptions)
        .then(handleSuccess)
        .catch(handleError)
}

function handleError(e) {
    console.log(e);
    throw e;
}

async function handleSuccess(response) {
    if (!response.ok) throw response;
    const string = await response.text();
    return string === "" ? {} : JSON.parse(string);
}

export default BendService;
