import Vue from 'vue'
import App from './App.vue'
import './styles/global.css'
import router from './router'
import store from './store'
import './assets/tailwind.css'

import smoothscroll from 'smoothscroll-polyfill';
import BendService from "@/services/Bend/BendService";
smoothscroll.polyfill();

Vue.config.productionTip = false

BendService.configure('kfXUG1kxTRO6aqCt9tl4Tg', 'u1QzSbS8zCjIPJhMhdjwLpeczXw5FtAJSrV6CDRe')

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

