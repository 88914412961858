import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/menus'
  },
  {
    path: '/signup',
    component: () => import(/* webpackChunkName: "signup" */ '@/views/Signup.vue')
  },
  {
    path: '/thanks',
    component: () => import(/* webpackChunkName: "thanks" */ '@/views/Thanks.vue')
  },
  {
    path: '/error',
    component: () => import(/* webpackChunkName: "error" */ '@/views/ErrorHandler.vue')
  },
  {
    path: '/demo',
    name: 'Demo',
    component: () => import(/* webpackChunkName: "demo" */ '@/views/Demo.vue')
  },
  {
    path: '/menus',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue')
  },
  {
    path: '/menus/:config',
    name: 'Menus',
    component: () => import(/* webpackChunkName: "menu" */ '@/views/Menu.vue')
  },
  {
    path: '/menus/:config/:menu',
    name: 'Menu',
    component: () => import(/* webpackChunkName: "menu" */ '@/views/Menu.vue')
  },
  {
    path: '/:config',
    name: 'Menu',
    component: () => import(/* webpackChunkName: "menu" */ '@/views/Menu.vue')
  },
  {
    path: '/:config/:menu',
    name: 'MenuForTable',
    component: () => import(/* webpackChunkName: "menu" */ '@/views/Menu.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
