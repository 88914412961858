export const BodyLockService = {
    lock: lock,
    unlock: unlock
}

const LOCK_CLASS = 'scroll-lock';
const BODY_CLASSLIST = document.querySelector('html').classList;

function lock() {
BODY_CLASSLIST.add(LOCK_CLASS);
}

function unlock() {
BODY_CLASSLIST.remove(LOCK_CLASS);
}
