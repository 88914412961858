<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState([
      'configLoaded'
    ]),
    showNavBar() {
      return !this.favesOpen && !this.menu
    }
  },
  watch: {
    configLoaded() {
      // console.log('configLoaded')
      this.$store.dispatch('restoreFavesFromSessionStorage')
    }
  },
  mounted() {
    if (this.$route.query.table) {
      this.$store.commit('setTable', this.$route.query.table)
    }
    // This is specific to the Demo view, do not remove this!
    if (this.$route.query.demo) {
      document.querySelector('html').classList.add('demo-mode')
    }
  }
}
</script>

<style>
#app {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

.scroll-lock {
  overflow: hidden;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

body {
  margin: 0;
}

html, body, #app {
  min-height: 100%;
  height: 1px;
}

/* The demo-mode class is applied only on the Demo screen */
html.demo-mode {
  /* firefox */
  scrollbar-width: none;
}
html.demo-mode::-webkit-scrollbar {
  /* chrome + safari */
  display: none;
}

</style>
