import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import {BodyLockService} from "@/services/BodyLockService";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    config: null,
    configLoaded: false,
    menuError: null,
    primaryColor: '#cc00cc',
    favesOpen: false,
    menuPickerOpen: false,
    selectedMenuItem: null,
    sectionPickerOpen: false,
    menus: [],
    activeMenuId: null,
    activeMenuSlug: null,
    lastMenuId: null,
    sectionId: null,
    business: {
      name: null,
      logo: null,
      indexText: null,
      showIndex: false,
      summonWaitstaffEnabled: false
    },
    faves: {},
    table: null,
    scrollPosition: 0,
  },
  mutations: {
    selectMenuItem(state, item) {
      BodyLockService.lock();
      state.selectedMenuItem = item;
    },
    deselectMenuItem(state) {
      BodyLockService.unlock();
      state.selectedMenuItem = null;
    },
    setFavesOpen(state, open) {
      if (open) {
        state.scrollPosition = window.scrollY
      } else {
        setTimeout(() => {
          window.scrollTo(0, state.scrollPosition)
        }, 10)
      }
      state.favesOpen = open
    },
    setMenuPickerOpen(state, open) {
      if (open) {
        state.scrollPosition = window.scrollY
        // keep track what the menu id was before the picker was opened
        state.lastMenuId = state.activeMenuId
        BodyLockService.lock();
      } else {
        // if menu picker was closed without changing menu, restore scroll position
        if (state.lastMenuId == state.activeMenuId) {
          setTimeout(() => {
            BodyLockService.unlock();
            window.scrollTo(0, state.scrollPosition)
          }, 10)
        }
      }
      state.menuPickerOpen = open
    },
    setSectionPickerOpen(state, open) {
      if (open) {
        state.scrollPosition = window.scrollY
        BodyLockService.lock();
      } else {
        setTimeout(() => {
          BodyLockService.unlock();
          window.scrollTo(0, state.scrollPosition)
        }, 10)
      }
      state.sectionPickerOpen = open
    },
    setActiveMenuId(state, id) {
      state.activeMenuId = id
      state.sectionId = null
    },
    setActiveMenuSlug(state, slug) {
      if (slug) {
        state.activeMenuSlug = slug
        state.activeMenuId = state.menus.find(menu => menu.slug === slug)._id
      } else {
        state.activeMenuSlug = null
        state.activeMenuId = null
      }
      state.sectionId = null
    },
    setSectionId(state, id) {
      // console.log('sectionId', id)
      state.sectionId = String(id)
    },
    setTable(state, id) {
      // console.log('sectionId', id)
      state.table = id
    },
    toggleFave(state, {menuId, itemId}) {
      // console.log('toggleFave', menuId, itemId)
      let newFaves = {}
      Object.keys(state.faves).forEach(menuId => {
        // console.log('menuId', menuId)
        newFaves[menuId] = {}
        Object.keys(state.faves[menuId]).forEach(itemId => {
          // console.log('itemId', itemId)
          newFaves[menuId][itemId] = state.faves[menuId][itemId]
        })
      })

      if (!newFaves[menuId]) {
        newFaves[menuId] = {}
      }

      if (newFaves[menuId][itemId]) {
        // console.log('it was truthy')
        newFaves[menuId][itemId] = false
      } else {
        // console.log('it was falsey')
        newFaves[menuId][itemId] = true
      }
      //console.log('newFaves', newFaves)
      state.faves = newFaves
      sessionStorage.setItem('faves', JSON.stringify(state.faves))
    },
    updateBusiness(state, data) {
      state.business = {
        ...state.business,
        name: data.name,
        logo: data.logo,
        indexText: data.indexText,
        showIndex: data.showIndex,
        summonWaitstaffEnabled: data.summonWaitstaffEnabled
      }
      state.config = data.config
      state.menus = []
    },
    updateMenuError(state, error) {
      state.menuError = error
    },
    updateConfigLoaded(state, loaded) {
      state.configLoaded = loaded
    },
    addMenu(state, menu) {
      // console.log(menu)
      let text = menu.text

      let sections = []

      let rawSections = text.split("\n\n\n\n")

      rawSections.forEach((block) => {
        let section = {}
        let itemBlocks = block.split("\n\n")
        // console.log(itemBlocks)

        let lines = itemBlocks[0].split("\n")
        // console.log(lines)

        section.name = lines[0].replace(/^#[ ]*/, '')
        // console.log('section.name', section.name)
        lines = lines.slice(1)
        section.description = lines.join('\n')
        // console.log('section.description', section.description)

        itemBlocks = itemBlocks.slice(1)

        let items = []
        itemBlocks.forEach(iblock => {
          let item = {}
          let lines = iblock.split("\n")
          // console.log('item lines', lines)

          item.name = lines[0].replace(/[*][ ]*/ , '')
          item._id = item.name.toLowerCase()

          let priceMatchData, imageMatchData;

          if (lines.length > 1) {
            let priceRegExp = new RegExp(/^([$][0-9]+[.]?[0-9]*)/)
            let imageRegExp = new RegExp(/\.(jpg|jpeg|png|gif)$/)

            priceMatchData = priceRegExp.exec(lines[1].trim())
            imageMatchData = imageRegExp.exec(lines[1].trim())
            if (!imageMatchData && lines.length > 2) {
              imageMatchData = imageRegExp.exec(lines[2].trim())
            }
          }

          // console.log('md', md)
          if (priceMatchData) {
            item.price = priceMatchData.input
          }

          if (imageMatchData) {
            item.imageUrl = imageMatchData.input
          }

          if (priceMatchData && imageMatchData) {
            item.description = lines.slice(3).join("\n")
          } else {
            item.description = lines.slice(2).join("\n")
          }
          items.push(item)
        })

        let sectionId = section.name.toLowerCase()
        // replace special characters with spaces
        sectionId = sectionId.replace(/[$&+,:;=?@#|'<>.-^*()%!]/g, ' ')
        // replace one or more spaces with a hyphen
        sectionId = sectionId.replace(/[ ]+/g, '-')

        section._id = sectionId
        section.menuId = menu._id
        section.items = items
        sections.push(section)
        
      })
      let parsedMenu = {...menu, sections}
      let menus = [...state.menus, parsedMenu]
      if (!state.faves[menu._id]) {
        state.faves = {...state.faves, [menu._id]: {}}
      }
      state.menus = menus.sort(m => m.position)
    }
  },
  actions: {
      async loadConfig({ state, commit }, {config, slug}) {
      // TODO Do a bunch of stuff to load the menu from Bend
      let res, bizData

      try {
        res = await axios.get(`/config/${config}/config.json`)
      } catch (error) {
        console.log('error', error)
        let niceError = `The menu <strong>${config}</strong> does not exist`
        commit('updateMenuError', niceError)
        return
      }
      bizData = res.data

      let root = document.documentElement;
      root.style.setProperty('--primary-color', bizData.primaryColor || state.primaryColor)

      commit('updateBusiness', {...bizData, config})

      let promises = []
      bizData.menus.forEach((menu, index) => {
        promises.push(
          axios.get(`/config/${config}/${menu.file}`).then(res => {
            let text = res.data
            commit('addMenu', {
              _id: menu.file,
              name: menu.name,
              text,
              position: index,
              sections: [],
              description: menu.description,
              slug: menu.slug
            })
          })
        )
      })

      Promise.all(promises).then(() => {
        commit('updateConfigLoaded', true)
        if (slug) {
          commit('updateActiveMenuSlug', slug)
        }
      })
    },
    restoreFavesFromSessionStorage({commit}) {
      let faves = sessionStorage.getItem('faves')
      // console.log(faves)
      if (faves) {
        try {
          faves = JSON.parse(faves)
          console.log(faves)
          Object.keys(faves).forEach(menuId => {
            // console.log('menuId', menuId)
            Object.keys(faves[menuId]).forEach(itemId => {
              // console.log('itemId', itemId)
              if (faves[menuId][itemId]) {
                commit('toggleFave', {menuId, itemId})
              }
            })
          })
        } catch (e) {
          console.log('error', e)
        }
      }
    }
  },
  getters: {
    menuForTimeOfDay(state) {
      // TODO: Add real logic
      return state.menus[0]
    },
    faveCount(state) {
      let count = 0
      Object.keys(state.faves).forEach(menuId => {
        // console.log('menuId', menuId)
        count += Object.keys(state.faves[menuId]).filter(itemId => state.faves[menuId][itemId]).length
      })
      return count
    },
    currentMenu(state, getters) {
      if (state.menus.length === 0) {
        return null
      }
      let menu;
      if (state.activeMenuSlug) {
        menu = state.menus.find(m => {
          return m.slug == state.activeMenuSlug
        })
        return menu
      } else if (state.business.showIndex) {
        return null
      }

      menu = getters.menuForTimeOfDay

      return menu
    },
    faveSections(state, getters) {
      if (!getters.currentMenu) {
        return [{
          _id: 'your-picks',
          name: 'Your Picks',
          items: []
        }]
      }
      let sections = []

      state.menus.forEach(menu => {
        let itemsByMenu = {}
        let foundItemIds = {}

        menu.sections.forEach(section => {
          section.items.forEach(item => {
            let key = `${menu._id}-${item._id}`
            if (state.faves[menu._id] && state.faves[menu._id][item._id] && !foundItemIds[key]) {
              if (!itemsByMenu[menu._id]) {
                itemsByMenu[menu._id] = []
              }
              itemsByMenu[menu._id].push(item)
              foundItemIds[key] = true
            }
          })
        })
        if (Object.keys(foundItemIds).length) {
          sections.push({
            _id: menu.name.replace(/[ \t\r\n]+/g, '-'),
            menuId: menu._id,
            name: `${menu.name} Picks`,
            items: itemsByMenu[menu._id]
          })
        }
    })

      if (sections.length) {
        return sections
      }
      return [{
        _id: 'your-picks',
        name: 'Your Picks',
        items: []
      }]
    }
  }
})
